import React from 'react'
import styled from 'styled-components'
import { ICompany } from '../../typescript/interfaces'
import { fluidOrSvgImg } from '../../utils/fluidOrSvgImg'
import Container from '../Container'

type Props = {
  title?: string
  companies: ICompany[]
}

const StaticMarquee: React.FC<Props> = ({ companies, title }) => {
  return (
    <Marquee>
      <Container>
        {title && <Title>{title}</Title>}
        <Track>
          <Ul>
            {companies.slice(0, 5).map((company, i) => (
              <Li key={company?.id}>
                <a href={company?.url} target='_blank' rel='noreferrer'>
                  <Inner>{company.imgSrc && fluidOrSvgImg({ imgSrc: company.imgSrc, alt: company.alt })}</Inner>
                </a>
              </Li>
            ))}
          </Ul>
        </Track>
      </Container>
    </Marquee>
  )
}

const Inner = styled.div`
  transition: all 0.5s ease;
  padding: 1.5rem;
  min-width: 10rem;
  width: 10rem;
  border-radius: 1000px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    min-width: 7rem;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.grey200};
    box-shadow: ${({ theme }) => theme.boxShadow.small};
  }
`

const Title = styled.p`
  font-weight: 500;
  font-size: 1.125rem;
  color: ${({ theme }) => theme.colors.grey500};
  margin: 0 auto;
  text-align: center;
  padding: 0 1.5rem 3rem;
`

interface MarqueeProps {
  transparent?: boolean
}

const Marquee = styled.article<MarqueeProps>`
  background: ${({ transparent }) => (transparent ? 'transparent' : '#fff')};
`

const Track = styled.div<MarqueeProps>`
  padding: 0 1.5rem;
  justify-content: center;
  overflow: hidden;
  display: flex;
  position: relative;
  ul:nth-of-type(2) {
    transform: translateX(100%);
  }
  &:after {
    content: '';
    pointer-events: none;
    background: linear-gradient(
      to right,
      #fff 0,
      rgba(255, 255, 255, 0.1) 10%,
      rgba(255, 255, 255, 0.1) 90%,
      #fff 100%
    );
    background: ${({ transparent, theme }) =>
      transparent
        ? `linear-gradient(to right, ${theme.colors.grey200} 0, rgba(255, 255, 255, 0.1) 10%, rgba(255, 255, 255, 0.1) 90%, ${theme.colors.grey200} 100%)`
        : `linear-gradient(to right, #fff 0, rgba(255, 255, 255, 0.1) 10%, rgba(255, 255, 255, 0.1) 90%, #fff 100%)`};
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  ${({ theme }) => theme.breakpoints.mobile} {
    padding: 0;
  }
`

const Ul = styled.ul`
  display: flex;
  align-items: center;
`

const Li = styled.li`
  a {
    width: auto;
    cursor: pointer;
  }
`

export default StaticMarquee
